<script lang="ts" setup>
const { $getLocale, $getLocales, $switchLocale } = useI18n()
</script>

<template>
  <FSelect
    name="language" size="sm" class="w-110px! p-4px!" variant="outline" :model-value="$getLocale()" :options="$getLocales().map((l) => ({ ...l, label: l.code.toUpperCase() }))"
    option-attribute="label" value-attribute="code" @update:model-value="$switchLocale($event)"
  >
    <template #value="slotProps">
      <div class="flex items-center" h-16px>
        <img :alt="slotProps.value" :src="`/flags/${slotProps.value}.svg`" mr="8px" style="width: 18px">
        <div text-xs>
          {{ slotProps.value.toUpperCase() }}
        </div>
      </div>
    </template>
    <template #option="slotProps">
      <div class="flex items-center">
        <img :alt="slotProps.option.label" :src="`/flags/${slotProps.option.code}.svg`" mr="8px" style="width: 18px">
      </div>
    </template>
  </FSelect>
</template>
