<script lang="ts" setup>
const userStore = useUser()
const { isLogged, isAdmin, isInspector, isExchangeOfficeOwner, user } = storeToRefs(userStore)
const { profileNavigation, mobileMenuOpen, navigation } = useNavigation()
const { $t, $switchRoute, $getLocale } = useI18n()
// const buttonVariant = useState<'primary' | 'accent'>('buttonVariant', () => 'primary')

// unocss safelist
// i-fubex-square-menu
// i-fubex-logout
// i-fubex-calculator
// i-fubex-lock
// i-fubex-settings
// i-fubex-bonus
// i-fubex-exchange
// i-fubex-history

const { isColorSwitcher } = useRuntimeConfig().public
</script>

<template>
  <header :data-test="getCypressId('header')" nav-bg fubex-shadow-md-down z-5>
    <nav
      fubex-container flex items-center justify-between h-88px px="16px" py="24px" w-full
    >
      <div flex mr-32px items-center gap-32px>
        <NavigationLogo />
        <span sr-only>Bitbeli</span>
      </div>
      <!-- menu icon -->
      <div v-if="isLogged" flex items-center xl:hidden>
        <button :data-test="getCypressId('mobile-menu')" gap-10px inline-flex items-center justify-center rounded-md text-neutral-900 primary-hover p-4px @click="mobileMenuOpen = true">
          <UnoIcon i-fubex-menu h-20px w-20px />
          <h2 paragraph-md font-500>
            {{ $t('components.menu') }}
          </h2>
        </button>
      </div>
      <div v-else xl-hidden flex flex-1 justify-end items-center gap-12px>
        <FColorModeSwitch v-if="isColorSwitcher" />
        <LanguageSelector :locale="$getLocale()" />
      </div>

      <!-- /menu items -->
      <div hidden xl="flex flex-1 justify-end" items-center gap-12px>
        <FColorModeSwitch v-if="isColorSwitcher" />
        <LanguageSelector :locale="$getLocale()" />
        <template v-if="isLogged">
          <ClientOnly>
            <HeadlessPopover relative right-0>
              <HeadlessPopoverButton flex items-center gap-x-1 paragraph-md text-neutral-900 primary-hover outline-none>
                <FButton name="navigation-user" variant="outline" size="md" :prefix-icon="user?.isCompany ? 'i-fubex-company' : 'i-fubex-user'" suffix-icon="i-fubex-chevron-down">
                  {{ user!.isCompany ? user!.companyName : user!.name }}
                </FButton>
              </HeadlessPopoverButton>
              <HeadlessTransitionRoot enter="transition ease-out duration-200" enter-from="opacity-0 translate-y-1" enter-to="opacity-100 translate-y-0" leave="transition ease-in duration-150" leave-from="opacity-100 translate-y-0" leave-to="opacity-0 translate-y-1">
                <HeadlessPopoverPanel class="absolute right-0 top-full z-10 mt-3 w-262px rounded-xl bg-background-primary p-2 fubex-shadow-lg-down ring-1 ring-gray-900/5">
                  <HeadlessPopoverButton
                    v-for="item in profileNavigation" :key="item.label"
                    :data-test="getCypressId(`navigation-${item.key}`)"
                    px-3 py-12px my-0 paragraph-md leading-6 text-neutral-900 primary-hover
                    flex items-center gap-8px
                    @click="$switchRoute(item.href)"
                  >
                    <UnoIcon :class="item.icon" h-20px w-20px />
                    {{ $t(`components.navigation.${item.trkey}`) }}
                  </HeadlessPopoverButton>

                  <HeadlessPopoverButton
                    v-if="isExchangeOfficeOwner"
                    px-3 py-12px my-0 paragraph-md leading-6 text-neutral-900 primary-hover
                    flex items-center gap-8px
                    @click="$switchRoute('/profile/exchangeoffice')"
                  >
                    <UnoIcon i-fubex-crypto h-20px w-20px />
                    {{ $t('components.myExchangeOffice') }}
                  </HeadlessPopoverButton>
                  <HeadlessPopoverButton
                    v-if="isInspector"
                    px-3 py-12px my-0 paragraph-md leading-6 text-neutral-900 primary-hover
                    flex items-center gap-8px
                    @click="$switchRoute('/profile/inspector/')"
                  >
                    <UnoIcon i-fubex-stats h-20px w-20px />
                    {{ $t('components.inspector') }}
                  </HeadlessPopoverButton>
                  <HeadlessPopoverButton
                    v-if="isAdmin"
                    px-3 py-12px my-0 paragraph-md leading-6 text-neutral-900 primary-hover
                    flex items-center gap-8px
                    @click="$switchRoute('/profile/admin/bank-accounts')"
                  >
                    <UnoIcon i-fubex-user-badge h-20px w-20px />
                    {{ $t('components.administration') }}
                  </HeadlessPopoverButton>
                </HeadlessPopoverPanel>
              </HeadlessTransitionRoot>
            </HeadlessPopover>
          </ClientOnly>
        </template>

        <!-- <template v-else>
          <WidgetAuthDialogs default-view="login">
            <FButton name="navigation-login" size="lg" variant="text">
              {{ $t('components.login') }}
            </FButton>
          </WidgetAuthDialogs>
          <WidgetAuthDialogs default-view="register">
            <FButton name="navigation-register" size="lg" prefix-icon="i-fubex-user" :variant="buttonVariant">
              {{ $t('components.register') }}
            </FButton>
          </WidgetAuthDialogs>
        </template> -->
      </div>
    </nav>

    <!-- mobile menu -->
    <ClientOnly>
      <HeadlessDialog as="div" xl:hidden :open="mobileMenuOpen" @close="mobileMenuOpen = false">
        <div fixed inset-0 z-10 bg-black opacity-70 />
        <HeadlessDialogPanel fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-background-primary px-16px py-24px sm:max-w-sm sm:ring-1 class="sm:ring-gray-900/10">
          <div v-show="mobileMenuOpen" flex items-center justify-between>
            <I18nLink to="/" @click="mobileMenuOpen = false">
              <img :src="`${'/img/dark/logo.svg'}`" alt="Fubex logo" h-34px w-auto hidden dark:block sm:hidden>
              <img :src="`${'/img/logo.svg'}`" alt="Fubex logo" h-34px w-auto dark:hidden sm:hidden>
            </I18nLink>

            <button :data-test="getCypressId('close-menu')" type="button" rounded-md p-2 text-neutral-900 primary-hover @click="mobileMenuOpen = false">
              <UnoIcon i-fubex-x-mark h-6 w-6 aria-hidden="true" />
            </button>
          </div>
          <div mt-6 flow-root>
            <div class="divide-y divide-gray-500/10">
              <div v-if="isLogged" fubex-rounded-lg p-24px bg-neutral-100 dark:bg-neutral-200>
                <div flex items-center gap-8px border-b-1px border-neutral-500 text-neutral-500>
                  <UnoIcon i-fubex-user h-20px w-20px /> {{ user!.name }} test
                </div>
                <div flex flex-col gap-16px py-24px>
                  <I18nLink v-for="item in profileNavigation" :key="item.label" :to="item.href" paragraph-md leading-6 @click="mobileMenuOpen = false">
                    <div flex items-center gap-8px>
                      <UnoIcon :class="item.icon" h-20px w-20px />
                      {{ $t(`components.navigation.${item.trkey}`) }}
                    </div>
                  </I18nLink>
                  <I18nLink v-if="isExchangeOfficeOwner" to="/profile/exchangeoffice" paragraph-md leading-6 @click="mobileMenuOpen = false">
                    <span w-fit flex gap-8px items-center>
                      <UnoIcon i-fubex-crypto h-20px w-20px />
                      <h2>{{ $t('components.myExchangeOffice') }}</h2>
                    </span>
                  </I18nLink>
                  <I18nLink v-if="isInspector" to="/profile/inspector/" paragraph-md leading-6 @click="mobileMenuOpen = false">
                    <span w-fit flex gap-8px items-center>
                      <UnoIcon i-fubex-stats h-20px w-20px />
                      <h2>{{ $t('components.inspector') }}</h2>
                    </span>
                  </I18nLink>
                  <I18nLink v-if="isAdmin" to="/profile/admin/bank-accounts" paragraph-md leading-6 @click="mobileMenuOpen = false">
                    <span w-fit flex gap-8px items-center>
                      <UnoIcon i-fubex-user-badge h-20px w-20px />
                      <h2>{{ $t('components.administration') }}</h2>
                    </span>
                  </I18nLink>
                </div>
              </div>
              <div space-y-2 pt-6>
                <div v-for="item in navigation" :key="item.label">
                  <HeadlessDisclosure v-if="item.subMenu" v-slot="{ open }" as="div" mx--3>
                    <HeadlessDisclosureButton :key="item.key" :data-test="getCypressId(`mobile-navigation-${item.key}`)" flex w-full items-center justify-between py-2 pl-3 pr-3.5 paragraph-md text-neutral-900 primary-hover>
                      {{ $t(`components.navigation.${item.trkey}`) }}
                      <UnoIcon i-fubex-chevron-down h-5 w-5 flex-none aria-hidden="true" :class="[open ? 'rotate-180' : '']" />
                    </HeadlessDisclosureButton>
                    <HeadlessDisclosurePanel mt-2 space-y-2>
                      <I18nLink v-for="subItem in item.subMenu" :key="subItem.label" :data-test="getCypressId(`mobile-navigation-${subItem.key}`)" :to="subItem.href" block py-2 pl-6 pr-3 paragraph-md leading-6 text-neutral-900 @click="mobileMenuOpen = false">
                        {{ subItem.trkey ? $t(`components.navigation.${subItem.trkey}`) : $t(`components.navigation.${subItem.key}`) }}
                      </I18nLink>
                    </HeadlessDisclosurePanel>
                  </HeadlessDisclosure>
                  <I18nLink v-else :key="item.key" :to="item.href" :data-test="getCypressId(`mobile-navigation-${item.key}`)" mx--3 block px-3 py-2 paragraph-md text-neutral-900 primary-hover @click="mobileMenuOpen = false">
                    {{ $t(`components.navigation.${item.trkey}`) }}
                  </I18nLink>
                </div>

                <div v-if="!isLogged" flex flex-col items-center gap-24px pt-36px>
                  <WidgetAuthDialogs default-view="login">
                    <div :data-test="getCypressId('mobile-navigation-login')" paragraph-md text-neutral-900 primary-hover font-600>
                      {{ $t('components.login') }}
                    </div>
                  </WidgetAuthDialogs>
                  <WidgetAuthDialogs default-view="register">
                    <FButton :data-test="getCypressId('mobile-navigation-register')" name="navigation-register" size="lg" prefix-icon="i-fubex-user">
                      {{ $t('components.register') }}
                    </FButton>
                  </WidgetAuthDialogs>
                </div>
                <div flex flex-col items-center justify-center gap-24px pt-36px>
                  <div v-if="isColorSwitcher" flex flex-col items-center gap-24px>
                    <button :data-test="getCypressId('mobile-dark-mode-switch')" flex items-center gap-4px>
                      <FColorModeSwitch />
                    </button>
                  </div>
                  <div flex flex-col items-center>
                    <LanguageSelector :locale="$getLocale()" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeadlessDialogPanel>
      </HeadlessDialog>
      <ClientOnly />
      <!-- /mobile menu -->
    </clientonly>
  </header>
  <div relative w-full>
    <Sun />
  </div>
</template>
